.table * {
  box-sizing: border-box !important;
}

.table-wrap {
  display: block;
  background-color: #FFF;
  position: relative;
}

.top-toolbar {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 10px;
  }
}

.table {
  position: relative;
  display: block;
  overflow: auto;
  white-space: nowrap;
  margin: 0 auto;
  padding: 0 20px;

  .scroll {
    max-height: 70vh;
    overflow-y: auto;
  }

  .header {
    margin-top: 20px;

    input {
      margin-top: 10px !important;
      padding: 4px !important;
      box-sizing: border-box !important;
    }
  }

  .group-header {
    background-color: #ffedad;
    padding: 10px;
  }

  .row {

    &.last-row {
      min-height: 25px;
    }

    line-height: 0.8;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-top: 1px solid #EEE;
    display: flex;
    align-items: flex-start;

    &:last-child {
      //border-bottom: 1px solid #EEE;
    }

    &.selected {
      background-color: #a8cdee;
    }

    &.drag {
      cursor: grab;
    }
    &.drag-border {
      border-top: 2px solid #282c34;
    }

    .cell {
      padding: 6px;
      min-height: 32px;
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      z-index: 10;
      border-right: 1px solid #EEE;

      &:last-child, &:first-child {
        border-right: 0px;
      }

      &:last-child {
        //border-right: 1px solid #EEE;
      }

      &.cell-selected {
        background-color: #e1a2ff;
      }

      &.system {
        background-color: #ffedad;
      }

      &.header {
        font-weight: bold;
        cursor: pointer;
        display: inline-block;
      }

      &.cursor {
        outline: 3px solid #000;
        z-index: 1000;
        border: 0px;
      }

      &.error {
        background-color: #ffbaba;
        //border: 1px solid #ff0000;
      }

      &.warning {
        background-color: #c8e1ff;
      }


      &.editor {
        padding: 0px !important;
        display: block;
        box-sizing: border-box;

        input {
          font-size: 14px;
          position: relative;
          height: 30px !important;
          max-height: 30px !important;
          display: block;
          width: 100%;
          padding-left: 5px !important;
          border: 0px;

          top: 0px;
          margin: 0 !important;
          outline: 0 !important;;
        }
      }

      .input-filter {
        display: block;
        width: 98%;
        margin: 15px 0 0 0 !important;
        padding: 0;
      }
    }

  }

  &:focus {
    outline: none;
  }

  .drag-rows-box {
    position: absolute;
    left: 0; top: 0;
    z-index: 2000;
    display: block;
    padding: 5px;
    background-color: #FFFFFF;
    // shadow
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    border-radius: 5px;
  }

}

.ant-select {
  position: relative;
  display: block;
  margin: 0 !important;
  padding: 0 !important;
}

.ant-select-selector {
  display: block;
  position: relative;
  display: block !important;
}

.ant-select-single.ant-select-customize-input, .ant-select-selection-search {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  right: unset;
}

.top-toolbar button {
  margin-left: 0
}
