.title-label {
  padding: 5px;
  min-height: 50px;
}

.ant-table-cell {
  vertical-align: top;
}

label.label {
  min-width: 270px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
}

table.bordered {
  border-collapse: collapse;
  td, th {
    border: 1px solid #AAA;
    padding: 3px 13px;
  }
}

// это чтобы всплывающий Tooltip был под ширину контента
.ant-tooltip {
  max-width: inherit !important;
}


.popup {
  z-index: 10000;
  position: absolute !important;
  background-color: #fff;
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;

  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}