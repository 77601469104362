.favorites {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  position: relative;

  .favorite-item {
    color: #02824b;
    margin-top: 20px;
    min-width: 200px;
    height: 160px;
    border: 1px solid rgba(2, 130, 75, 0.15);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
    padding: 10px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    .title {
      text-align: center;
      position: relative;
      top: -10px;
    }

    &:hover {
      background-color: rgba(2, 130, 75, 0.05);
    }

    & .del {
      button {
        border: none;
        scale: 0.6;
      }

      position: absolute;
      right: 5px;
      top: 5px;
    }

    & .buttons {
      position: absolute;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      bottom: 0px;
      background-color: #f3f3f3;
      width: 100%;
      height: 40px;

      button {
        color: #68bcff;
        padding: 0;
        padding-top: 1px;
        margin: 0;
        width: 24px;
        height: 24px;
      }
    }
  }
}

