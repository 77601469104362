.detail-header {
  display: flex;
  justify-content: space-between;
  .item {
    display: inline-block;
  }
}

.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: #fffd8c;
}

.ant-table-row.selected {
  background-color: #fffd8c !important;
  &:hover {
    background-color: #fffd8c !important;
  }
}